import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Jumbotron, Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const Equipe = ({ data }) => (
  <Layout>
    <SEO title="Notre équipe" />
    <Jumbotron fluid>
      <Container>
        <h1>Notre équipe</h1>
        <p>
          Nous sommes une équipe de jeunes professionnels diplomés et passionnés
          par le milieu maritime. Dynamiques et à l'écoute, nous vous
          accompagnerons lors de toutes nos prestations dans les meilleurs
          conditions de sécurité et dans le respect de l'environnement.
        </p>
        <p>
          Notre devise est : "<b>PÉDAGOGIE</b> , <b>CONVIVIALITÉ</b> et
          <b> PARTAGE</b>".
        </p>
      </Container>
    </Jumbotron>
    <Container fluid="md">
      <Row>
        <Col>
          <div className="pb-4">
            <Img
              className="rounded-circle img-fluid mx-auto d-block"
              fixed={data.titeuf.childImageSharp.fixed}
              alt="Christophe Barriola guide pêche capreton"
            />
          </div>
          <p>
            Bonjour, je me nomme Christophe Barriola. Mon environnement « à
            quelques centaines de mètres de l’océan » m’a permis depuis le plus
            jeune âge de me passionner pour le milieu maritime. Dès l’âge de 10
            ans, j’ai intégré une école de pêche en bord de mer (Surf Casting)
            qui m’a permis l’apprentissage de cette technique. Très vite, j’ai
            commencé à réaliser des compétitions et à décrocher des titres,
            notamment champion de France jeune espoir ainsi que vice champion du
            monde par équipe.
          </p>
          <p>
            À 18 ans, j’ai obtenu un brevet fédéral de moniteur d’enseignement
            de la pêche et des connaissances de la mer. Celui-ci m’a permis
            d’enseigner bénévolement au seins d’associations de la FFPM
            (Fédération française de pêche en mer) afin de permettre la
            transmission de mon savoir et de mes expériences. Pour ce qui est de
            mes études, passionné par l’océan, les bateaux et la pêche, il était
            évident de les réaliser dans le domaine maritime. J’ai donc commencé
            par acquérir des diplômes de mécanicien et électromécanicien marine
            :
          </p>
          <ul>
            <li>Chef mécanicien 250KW</li>
            <li>Chef mécanicien 750KW</li>
            <li>Bac professionnel électromécanicien marine</li>
          </ul>
          <p>
            Suite à 2 années dans la vie active, j’ai décidé d’intégrer la
            formation BPJEPS moniteur/guide de pêche ainsi que l'UCC MER. <br />
            Après l'obtention de ces deux diplômes avec succès, j ai réalisé un
            dernier diplôme : Capitaine 200 et le module pêche. <br />
            Aujourd'hui je suis Capitaine et guide de pêche en mer ( bateau et
            bord de mer ) .
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const data = graphql`
  query {
    titeuf: file(relativePath: { eq: "equipe/avatarTiteuf.jpg" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Equipe
